import { ListItemButton, ListItem, List } from "@mui/material";
import { DRAWER_LINKS } from "enums";
import { useAddAccountNumberPostfix, useAppDispatch, useAppSelector } from "hooks/hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { logout } from "actions/authentication/authentication";
import { Toolbar } from "@mui/material";
import { setSelectedMenu } from "actions/layout/layout";
import { Button } from "components";
import { siwtchPropBasedOnLang } from "helpers";
import { useTranslations } from "hooks/UseTranslations";
import { capitalize, checkProductsUrl, filterProductsByGroupId } from "utils/utils";
import StorageService from "services/storage.service";
import properties from "properties";
import styles from "../../layout.module.scss";

const DrawerItems = ({ classes, purchasingLayout, handleDrawerToggle, handleLanguageOpen }: any) => {
    const { navigate } = useAddAccountNumberPostfix();
    const nav = useNavigate();
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { translations } = useTranslations();
    const {
        authenticationReducer: { userProfile, isLoggedIn },
        layoutReducer: { menuItems, publicMenuItems, selectedMenu, selectedSidebar, isProductsInlineUrl },
        homePageReducer: { products, locations, language }
    } = useAppSelector((state) => state);
    const [searchParams] = useSearchParams();
    const merchantNumber = StorageService.getDataByKey("merchantData")?.merchantNumber;
    const accountNumber = searchParams.get("account_number");
    const menuItemsData = accountNumber ? menuItems : publicMenuItems;

    const homeCareExist = locations.some((e) => e.id === properties.HOMECARE_ACCOUNT);
    const findLocationButton = Boolean(pathname === "/web/products" && merchantNumber === properties.HOMECARE_ACCOUNT);
    const homeCareDitDev =
        merchantNumber !== properties.HOMECARE_ACCOUNT && !(properties.ENV === "dit" || properties.ENV === "dev");
    const isAirport = ["0000008480", "0000005995"].includes(merchantNumber);

    const handleClick = (target: string) => {
        handleDrawerToggle();
        navigate(target);
    };

    const handleLogout = () => {
        dispatch(logout()).then(() => {
            nav("/web/login", {
                state: { lastRoute: window.location.pathname + window.location.search }
            });
        });
    };

    const navigateTo = (categoryId?: Number) => {
        dispatch(setSelectedMenu(categoryId, false, menuItemsData));
        accountNumber
            ? nav(
                `/web/${checkProductsUrl(
                    isProductsInlineUrl ? "productsinline" : "products"
                )}?account_number=${merchantNumber}&group_id=${categoryId}`
            )
            : nav(
                `/web/${checkProductsUrl(isProductsInlineUrl ? "productsinline" : "products")}?group_id=${categoryId}`
            );
    };

    const homeCareButton = () => {
        if (pathname === "/web/locations" && homeCareExist) {
            return (
                <div className="sidebar-bottom-wrap">
                    <Button
                        class="home-care-btn"
                        label="Home Care"
                        handleClick={() => {
                            nav(
                                // `products?account_number=${properties.HOMECARE_ACCOUNT}&group_id=${properties.HOMECARE_MERCHANT_DIAGNOSTICS_CATEGORY_ID}`
                                `products?account_number=${properties.HOMECARE_ACCOUNT}`
                            );
                            dispatch(setSelectedMenu("locations", false));
                            handleDrawerToggle();
                        }}
                    />
                </div>
            );
        }
        if (findLocationButton && (properties.ENV === "dit" || properties.ENV === "dev")) {
            return (
                <div className="sidebar-bottom-wrap">
                    <Button
                        class="home-care-btn"
                        label={translations("label.find_location")}
                        handleClick={() => {
                            navigate(`locations`);
                            dispatch(setSelectedMenu("locations", false));
                            handleDrawerToggle();
                        }}
                    />
                </div>
            );
        }
        return null;
    };
    const showLocations = properties.ENV === "dit" || properties.ENV === "dev" ? !findLocationButton : homeCareDitDev;

    return (
        <>
            {isProductsInlineUrl ? null : (
                <Toolbar className={classes.logo} sx={{ display: { xs: "flex", lg: "none" } }}>
                    <div onClick={() => navigate("/web/products")} className={styles.ecocareLogoSvg} />
                </Toolbar>
            )}
            <List className={classes.list}>
                {purchasingLayout ? (
                    <>
                        {isLoggedIn ? (
                            <div className="account-info" onClick={() => navigate("/web/dashboard")}>
                                <i className="icon-dashboard-master" style={{ margin: "0px 10px" }} />
                                <p className="primary">{translations("label.my_ecocare_dashboard")}</p>
                            </div>
                        ) : (
                            <div className="account-info" onClick={() => navigate("/web/login")}>
                                <i className="user-icon" />
                                <p className="primary">{translations("action.log_in")}</p>
                            </div>
                        )}
                        {showLocations && !isProductsInlineUrl && !isAirport ? (
                            <ListItem disablePadding>
                                <ListItemButton
                                    className={
                                        selectedMenu.parent[0]?.text === "locations"
                                            ? `${classes.listItem} active`
                                            : `${classes.listItem}`
                                    }
                                    onClick={() => {
                                        navigate("locations");
                                        dispatch(setSelectedMenu("locations", false));
                                        handleDrawerToggle();
                                    }}>
                                    {translations("title.locations")}
                                </ListItemButton>
                            </ListItem>
                        ) : null}

                        {menuItemsData?.map((item, idx) => {
                            const groupIdItems = filterProductsByGroupId(
                                { id: item?.product_category_id, menuItems: menuItemsData },
                                products
                            )?.length;
                            return groupIdItems === 0 ? null : (
                                <ListItem key={idx} disablePadding>
                                    <ListItemButton
                                        className={
                                            selectedMenu.parent === item.product_category
                                                ? `${classes.listItem} active`
                                                : `${classes.listItem} `
                                        }
                                        onClick={() => {
                                            navigateTo(item.product_category_id);
                                            handleDrawerToggle();
                                        }}>
                                        {capitalize(siwtchPropBasedOnLang(item.product_category, language))}
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}

                        <div className="divider" style={{ marginBottom: "20px" }}></div>
                        {homeCareButton()}
                        <div className={`${styles.sidebarLanguageBtn}`}>
                            <div className={styles.languagesContainer} onClick={handleLanguageOpen}>
                                <i className={styles.languageIcon} />
                                <div className={styles.languageTitle}>{language?.toUpperCase()}</div>
                            </div>
                        </div>
                        {isLoggedIn ? (
                            <div className="sign-out-sidebar mt-2" onClick={handleLogout}>
                                <p>{translations("label.sign_out")}</p>
                            </div>
                        ) : null}
                    </>
                ) : (
                    <>
                        <div className={`${styles.sidebarWrapperDashboard} `}>
                            {isLoggedIn ? (
                                <div className="account-info">
                                    <i className="user-icon" />
                                    <div className="d-flex" style={{ flexDirection: "column" }}>
                                        <p className="primary">{`${userProfile?.person?.given_name} ${userProfile?.person?.name}`}</p>
                                        <p className="secondary">{translations("label.customer")}</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="account-info" onClick={() => navigate("/web/login")}>
                                    <i className="user-icon" />
                                    <p className="primary">{translations("action.log_in")}</p>
                                </div>
                            )}
                        </div>
                        {DRAWER_LINKS.map((el, idx) => {
                            // el.key === "chat";
                            return (
                                <ListItem key={idx} disablePadding>
                                    <ListItemButton
                                        className={
                                            selectedSidebar === el.key
                                                ? `${classes.listItem} active`
                                                : `${classes.listItem}`
                                        }
                                        onClick={() => handleClick(el.target)}>
                                        <i
                                            className={
                                                selectedSidebar === el.key
                                                    ? `${el.icon} mr-1 active`
                                                    : `${el.icon} mr-1`
                                            }
                                        />
                                        {translations(el.display)}
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                        <div className={styles.listButtonWrapper}>
                            <button
                                className={`${classes.listItem} last-drawer-link`}
                                onClick={() => handleClick("/web/home-testing/manually")}>
                                {translations("button.activate_Kit")}
                                <i className="icon-last-item " />
                            </button>
                        </div>
                        <div className={styles.sidebarWrapperDashboard}>
                            <div className="divider" style={{ marginBottom: "20px" }}></div>
                        </div>
                        <div className={`${styles.sidebarLanguageBtn} ${styles.dashboard}`}>
                            <div className={styles.languagesContainer} onClick={handleLanguageOpen}>
                                <i className={styles.languageIcon} />
                                <div className={styles.languageTitle}>{language?.toUpperCase()}</div>
                            </div>
                        </div>
                        {isLoggedIn ? (
                            <div className={`${styles.sidebarWrapperDashboard} mt-2`}>
                                <div className="sign-out-sidebar" onClick={handleLogout}>
                                    <p>{translations("label.sign_out")}</p>
                                </div>
                            </div>
                        ) : null}
                    </>
                )}
            </List>
        </>
    );
};

export default DrawerItems;
