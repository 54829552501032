import MediaContextProvider from "context/MediaContext";
import { Outlet } from "react-router-dom";


const MediaStreamMiddleware = () => {
    return <MediaContextProvider>
        <Outlet />
    </MediaContextProvider>
};

export default MediaStreamMiddleware;
