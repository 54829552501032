import { useState, useEffect } from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { Toolbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { WithLoader } from "components";
import { WHITELISTED_ROUTES_FOR_SIDEBAR, ORDERED_PRODUCTS_DEFAULT_BEGINING_DATE } from "enums";
import { currentDate } from "utils/utils";
import { getProductOrders } from "actions/dashboard/dashboard";
import MyQrCode from "pages/Dashboard/MyQrCode/MyQrCode";
import SideBar from "../Sidebar/Sidebar";
import AppBarDashboard from "./AppBar/AppBarDashboard";
import useCheckMobileScreen from "hooks/UseCheckMobileScreen/UseCheckMobileScreen";

const DashboardLayout = ({ handleProfileOpen, handleLanguageOpen, classes }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isMobile = useCheckMobileScreen(1200);
    const [searchParams] = useSearchParams();
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);
    let token = searchParams.get("token");

    const {
        authenticationReducer: { userProfile },
        dashboardReducer: { action, showQrCodePopup }
    } = useAppSelector((state) => state);

    const showSidebar = Boolean(
        WHITELISTED_ROUTES_FOR_SIDEBAR.includes(location.pathname) ||
        location.pathname.includes("my-health-profile") ||
        location.pathname.includes("my-orders") ||
        location.pathname.includes("chat") ||
        (isMobile && (location.pathname.includes("home-testing") || location.pathname.includes("consultation")))
    );

    const handleDrawerToggle = () => {
        isMobile && setMobileOpen(!mobileOpen);
        return;
    };

    useEffect(() => {
        if (userProfile?.account_number) {
            dispatch(
                getProductOrders(userProfile.account_number, ORDERED_PRODUCTS_DEFAULT_BEGINING_DATE, currentDate())
            );
        }
    }, [dispatch, userProfile]);

    return (
        <>
            {!token ? (
                <>
                    <AppBarDashboard
                        handleDrawerToggle={handleDrawerToggle}
                        handleProfileOpen={handleProfileOpen}
                        classes={classes}
                        handleLanguageOpen={handleLanguageOpen}
                    />

                    {showSidebar && (
                        <SideBar
                            handleDrawerToggle={() => handleDrawerToggle()}
                            mobileOpen={mobileOpen}
                            purchasingLayout={false}
                            handleLanguageOpen={handleLanguageOpen}
                        />
                    )}

                    <main className={classes.content}>
                        <Toolbar />
                        <WithLoader isLoading={action.isLoading && location.pathname !== "/web/home-testing" && !location.pathname.includes("/web/my-health-profile")}>
                            <div
                                className={`dashboard-layout ${location.pathname === "/web/p2p-video-consultation-room" || window.location.pathname.includes("/web/video-consultation-room")
                                    ? "consultationRoomLayout"
                                    : null
                                    }`}>
                                <MyQrCode open={showQrCodePopup} />
                                <Outlet />
                            </div>
                        </WithLoader>
                    </main>
                </>
            ) : (
                <div className={classes.mobileOutlet}>
                    <Outlet />
                </div>
            )}
        </>
    );
};

export default DashboardLayout;
