import { createContext, useContext, useRef, useState } from 'react';

const MediaContext = createContext<any>(undefined);

export default function MediaContextProvider({ children }: any) {
  const [localVideo, setLocalVideo] = useState(true);
  const [localAudio, setLocalAudio] = useState(true);
  const localMedia = useRef<HTMLVideoElement>();
  const remoteMedia = useRef<HTMLVideoElement>();
  const [stream, setStream] = useState<MediaStream | undefined>();

  const handleMic = () => {
    let audioTrack = stream!.getTracks().find((track) => track.kind === 'audio');
    if (audioTrack!.enabled) {
      setLocalAudio(false);
      audioTrack!.enabled = false;
    } else {
      setLocalAudio(true);
      audioTrack!.enabled = true;
    }
  };

  const handleCam = () => {
    let videoTrack = stream!.getTracks().find((track) => track.kind === 'video');
    if (videoTrack!.enabled) {
      setLocalVideo(false);
      videoTrack!.enabled = false;
    } else {
      setLocalVideo(true);
      videoTrack!.enabled = true;
    }
  };

  return (
    <MediaContext.Provider
      value={{
        localMedia: localMedia,
        remoteMedia: remoteMedia,
        localVideo: localVideo,
        localAudio: localAudio,
        stream,
        setLocalAudio: setLocalAudio,
        setLocalVideo: setLocalVideo,
        setStream: setStream,
        handleCam: handleCam,
        handleMic: handleMic,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
}

export const useMediaContext = (): any => {
  const context = useContext(MediaContext);
  if (!context) {
    throw new Error('Error');
  }
  return context;
};
