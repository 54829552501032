// import { showQrCodePopup } from "actions/dashboard/dashboard";
// import { store } from "reducers/store";
import properties from "properties";

export const ENVIRONMENT = properties.ENV;

export const ORDERED_PRODUCTS_DEFAULT_BEGINING_DATE = "2015-01-01";

export const DRAWER_LINKS = [
    {
        key: "dashboard",
        icon: "icon-dashboard",
        display: "title.dashboard",
        target: "/web/dashboard"
    },
    {
        key: "my-upcomings",
        icon: "icon-myAppointments",
        display: "title.my_upcomings",
        target: "/web/my-upcomings"
    },
    {
        key: "my-orders",
        icon: "icon-myOrders",
        display: "title.my_orders",
        target: "/web/my-orders"
    },
    {
        key: "my-health-profile",
        icon: "icon-myHealthProfile",
        display: "title.my_health_profile",
        target: "/web/my-health-profile?tab=biomarkers"
    },
    // {
    //     key: "chat",
    //     icon: "icon-chat",
    //     display: "title.chat",
    //     target: "/web/chat"
    // },
    // {
    //   key: "my-reviews",
    //   icon: "icon-myReviews",
    //   display: "title.my_reviews",
    //   target: "/dashboard",
    // },
    // {
    //   key: "my-wishlist",
    //   icon: "icon-myWishlist",
    //   display: "title.my_wishlist",
    //   target: "/dashboard",
    // },
    {
        key: "my-account",
        icon: "icon-myAccount",
        display: "title.my_account",
        target: "/web/my-account"
    },
    {
        key: "family-and-friends",
        icon: "icon-familyAndFriends",
        display: "title.family_members",
        target: "/web/family-and-friends"
    }
    // {
    //     key: "my-qr-code",
    //     icon: "icon-myQRCode",
    //     display: "title.my_qr-code",
    //     onClick: () => store.dispatch(showQrCodePopup())
    //     // target: "/web/qr-code",
    // }
    // {
    //   key: "Settings",
    //   icon: "icon-settings",
    //   display: "Settings",
    //   target: "/dashboard",
    // },
    // {
    //     key: "activate-test",
    //     icon: "icon-last-item",
    //     display: "Activate Test",
    //     target: "/web/home-testing/manually"
    // }
];

export const WHITELISTED_PURCHASING_LAYOUT_ROUTES = [
    "/web/cart",
    "/web/book-appointment",
    "/web/product/details",
    "/web/products",
    "/web/productsinline",
    "/web/shipping-informations",
    "/web/checkout",
    "/web/booked-screen",
    "/web/traveler-screen",
    "/web/paypal",
    "/web/locations"
];

export const WHITELISTED_ROUTES_FOR_SIDEBAR = [
    "/web/dashboard",
    "/web/my-health-profile",
    "/web/my-health-profile/test",
    "/web/my-health-profile/biomarker",
    "/web/my-health-profile/consultations",
    "/web/my-orders",
    "/web/my-upcomings",
    "/web/my-account",
    "/web/family-and-friends",
    "/web/my-orders/view-order",
    "/web/appointments-history",
    "/web/chat",
    "/web/upload-documents"
];

export const PAGINATE_PER_PAGE = 10;

export const PAGINATE_PAGE_SIZES = [10, 50];

export const CONSULTATION_INSTRUCTIONS = [
    {
        number: "01",
        text: "consultation.instructions_1"
    },
    {
        number: "02",
        text: "consultation.instructions_2"
    },
    {
        number: "03",
        text: "consultation.instructions_3"
    },
    {
        number: "04",
        text: "consultation.instructions_4"
    },
    {
        number: "05",
        text: "consultation.instructions_5"
    },
    {
        number: "06",
        text: "consultation.instructions_6"
    },
    {
        number: "07",
        text: "consultation.instructions_7"
    },
    {
        number: "08",
        text: "consultation.instructions_8"
    },
    {
        number: "09",
        text: "consultation.instructions_9"
    }
];

export const SAMPLE_TAKING_INSTRUCTIONS = [
    {
        number: "1",
        name: "sample_taking.instructions_1"
    },
    {
        number: "2",
        name: "sample_taking.instructions_2"
    },
    {
        number: "3",
        name: "sample_taking.instructions_3"
    },
    {
        number: "4",
        name: "sample_taking.instructions_4"
    },
    {
        number: "5",
        name: "sample_taking.instructions_5"
    },
    {
        number: "6",
        name: "sample_taking.instructions_6"
    },
    {
        number: "7",
        name: "sample_taking.instructions_7"
    },
    {
        number: "8",
        name: "sample_taking.instructions_8"
    },
    {
        number: "9",
        name: "sample_taking.instructions_9"
    },
    {
        number: "10",
        name: "sample_taking.instructions_10"
    }
];

export const MAX_FILE_SIZE = 2000000; //2MB

export const WHITELISTED_FILE_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/jpg", "audio/mp3", "audio/mpeg"];

export const ALL_PRODUCTS_FILTER = [
    { text: "All", language: "en" },
    { text: "Alle", language: "de" }
];

export const HOME_PRODUCTS = ["C", "H", "A"];
export const ON_SITE_PRODUCTS = ["A", "G", "P", "V", "D", "F", "R"];
export const DOCTOR_LETTER_IDS = [1, 28]
